@import "@aviatar/bootstrap/src/scss/helpers";
@import '@syncfusion/ej2-base/styles/bootstrap4.css';
@import '@syncfusion/ej2-layouts/styles/bootstrap4.css';

:host {
  display: flex;
  width: 100%;
  height: 100%;
}

.avi-site-content-aside-content {
  overflow: hidden;
}

.avi-site-content-aside-primary.sidebar-visible {
  border-right: 0;
}
/* syncfusion splitter styling */
.e-splitter {
  &.e-splitter-horizontal {
    border: 0;
  }

  &.e-splitter-horizontal .e-pane.e-pane-horizontal.e-pane-hidden {
    flex-basis: 40px !important;
  }

  .e-split-bar.e-split-bar-horizontal .e-resize-handler { // split grip in the
    background: transparent;
    margin-left: -0.5px;
    &::before {
      color: $anthracite;
      font-size: 14px;
    }
  }

  .e-split-bar {
    &.e-split-bar-horizontal, &.e-split-bar-horizontal.e-resizable-split-bar::after {
      z-index: 0;
    }
    &.e-split-bar-horizontal.e-last-bar:not(.e-resizable-split-bar) {
      display: none; // hide splitter if collapsed
    }

    &.e-split-bar-horizontal.e-resizable-split-bar::after { // fix to prevent vertical splitter "cursor grip" is above scrollbar
      width: 8px;
      margin-left: 8px;
    }

    .e-navigate-arrow {
      display: none !important; // hide splitter collapse arrow, we use our own 🙈
    }
  }

}
