.e-avatar {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  background-color: #e9ecef;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  color: #212529;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 1.05em;
  font-weight: 400;
  height: 2.5em;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: hidden;
  position: relative;
  width: 2.5em;
}
.e-avatar img {
  height: 100%;
  width: auto;
}
.e-avatar.e-avatar-circle {
  border-radius: 50%;
}
.e-avatar.e-avatar-circle.e-avatar-large, .e-avatar.e-avatar-circle.e-avatar-xlarge {
  border-radius: 50%;
}
.e-avatar.e-avatar-xsmall {
  font-size: 0.65em;
}
.e-avatar.e-avatar-small {
  font-size: 0.85em;
}
.e-avatar.e-avatar-large {
  font-size: 1.25em;
}
.e-avatar.e-avatar-xlarge {
  font-size: 1.45em;
}

/*! card layout */
.e-bigger .e-card,
.e-bigger.e-card {
  line-height: 45px;
  min-height: 45px;
}
.e-bigger .e-card > .e-card-title,
.e-bigger.e-card > .e-card-title {
  font-size: 20px;
}
.e-bigger .e-card .e-card-content,
.e-bigger.e-card .e-card-content {
  font-size: 16px;
}
.e-bigger .e-card > .e-card-header-title,
.e-bigger.e-card > .e-card-header-title {
  line-height: normal;
  padding: 20px;
}
.e-bigger .e-card > .e-card-header-title + :not(.e-card-header-title),
.e-bigger.e-card > .e-card-header-title + :not(.e-card-header-title) {
  margin-top: 0;
  padding-top: 0;
}
.e-bigger .e-card > .e-card-title,
.e-bigger.e-card > .e-card-title {
  line-height: normal;
  padding: 20px;
}
.e-bigger .e-card > .e-card-title + :not(.e-card-title),
.e-bigger.e-card > .e-card-title + :not(.e-card-title) {
  margin-top: 0;
  padding-top: 0;
}
.e-bigger .e-card.e-card-horizontal .e-card-image,
.e-bigger .e-card.e-card-horizontal img,
.e-bigger .e-card .e-card-horizontal .e-card-image,
.e-bigger .e-card .e-card-horizontal img,
.e-bigger.e-card.e-card-horizontal .e-card-image,
.e-bigger.e-card.e-card-horizontal img,
.e-bigger.e-card .e-card-horizontal .e-card-image,
.e-bigger.e-card .e-card-horizontal img {
  margin: 2px;
}
.e-bigger .e-card .e-card-header,
.e-bigger.e-card .e-card-header {
  min-height: 30px;
  padding: 20px;
}
.e-bigger .e-card .e-card-header .e-card-content,
.e-bigger.e-card .e-card-header .e-card-content {
  padding-left: 0;
  padding-right: 0;
}
.e-bigger .e-card .e-card-header .e-card-header-caption,
.e-bigger.e-card .e-card-header .e-card-header-caption {
  padding: 0 0 0 12px;
}
.e-bigger .e-card .e-card-header .e-card-header-caption:first-child,
.e-bigger.e-card .e-card-header .e-card-header-caption:first-child {
  padding: 0;
}
.e-bigger .e-card .e-card-header .e-card-header-caption .e-card-header-title,
.e-bigger.e-card .e-card-header .e-card-header-caption .e-card-header-title {
  font-size: 20px;
  line-height: 24px;
}
.e-bigger .e-card .e-card-header .e-card-header-caption .e-card-sub-title,
.e-bigger.e-card .e-card-header .e-card-header-caption .e-card-sub-title {
  font-size: 16px;
  line-height: 19px;
  padding: 12px 0 0;
}
.e-bigger .e-card .e-card-header .e-card-header-image,
.e-bigger.e-card .e-card-header .e-card-header-image {
  background-size: cover;
  height: 40px;
  width: 40px;
}
.e-bigger .e-card .e-card-image,
.e-bigger.e-card .e-card-image {
  min-height: 150px;
}
.e-bigger .e-card .e-card-image .e-card-title,
.e-bigger.e-card .e-card-image .e-card-title {
  font-size: 20px;
  line-height: 36px;
  min-height: 36px;
  padding: 0 20px;
}
.e-bigger .e-card .e-card-actions,
.e-bigger.e-card .e-card-actions {
  padding: 20px;
}
.e-bigger .e-card .e-card-actions.e-card-vertical,
.e-bigger.e-card .e-card-actions.e-card-vertical {
  padding: 20px;
}
.e-bigger .e-card .e-card-actions.e-card-vertical .e-card-btn,
.e-bigger .e-card .e-card-actions.e-card-vertical a,
.e-bigger.e-card .e-card-actions.e-card-vertical .e-card-btn,
.e-bigger.e-card .e-card-actions.e-card-vertical a {
  margin: 0 0 10px 0;
}
.e-bigger .e-card .e-card-actions.e-card-vertical .e-card-btn:last-child,
.e-bigger .e-card .e-card-actions.e-card-vertical a:last-child,
.e-bigger.e-card .e-card-actions.e-card-vertical .e-card-btn:last-child,
.e-bigger.e-card .e-card-actions.e-card-vertical a:last-child {
  margin-bottom: 0;
}
.e-bigger .e-card .e-card-actions .e-card-btn span,
.e-bigger .e-card .e-card-actions .e-card-btn span.e-icons,
.e-bigger.e-card .e-card-actions .e-card-btn span,
.e-bigger.e-card .e-card-actions .e-card-btn span.e-icons {
  height: 24px;
  width: 24px;
}
.e-bigger .e-card .e-card-actions .e-card-btn,
.e-bigger .e-card .e-card-actions a,
.e-bigger.e-card .e-card-actions .e-card-btn,
.e-bigger.e-card .e-card-actions a {
  line-height: 38px;
  margin: 0 0 0 20px;
  min-height: 38px;
  padding: 0 6px;
}
.e-bigger .e-card .e-card-actions .e-card-btn:first-child,
.e-bigger .e-card .e-card-actions a:first-child,
.e-bigger.e-card .e-card-actions .e-card-btn:first-child,
.e-bigger.e-card .e-card-actions a:first-child {
  margin-left: 0;
}
.e-bigger .e-card .e-card-content,
.e-bigger.e-card .e-card-content {
  padding: 20px;
}
.e-bigger .e-card .e-card-content + :not(.e-card-content),
.e-bigger .e-card .e-card-content + .e-card-actions.e-card-vertical,
.e-bigger.e-card .e-card-content + :not(.e-card-content),
.e-bigger.e-card .e-card-content + .e-card-actions.e-card-vertical {
  margin-top: 0;
  padding-top: 0;
}

.e-card {
  border-radius: 4px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 15px;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: 36px;
  min-height: 36px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 100%;
}
.e-card > * {
  -ms-flex-pack: center;
      justify-content: center;
}
.e-card > .e-card-header-title {
  box-sizing: border-box;
  font-size: 18px;
  line-height: normal;
  padding: 16px;
}
.e-card > .e-card-header-title + :not(.e-card-header-title) {
  margin-top: 0;
  padding-top: 0;
}
.e-card > .e-card-title {
  font-size: 18px;
  line-height: normal;
  padding: 16px;
}
.e-card > .e-card-title + :not(.e-card-title) {
  margin-top: 0;
  padding-top: 0;
}
.e-card > .e-card-header-title,
.e-card > .e-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-card .e-card-header-caption {
  line-height: normal;
}
.e-card .e-card-header-caption .e-card-header-title {
  font-size: 14px;
}
.e-card .e-card-header-caption .e-card-sub-title {
  font-size: 14px;
}
.e-card .e-card-stacked {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: hidden;
}
.e-card.e-card-horizontal,
.e-card .e-card-horizontal {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
}
.e-card.e-card-horizontal .e-card-image,
.e-card.e-card-horizontal img,
.e-card .e-card-horizontal .e-card-image,
.e-card .e-card-horizontal img {
  margin: 2px;
}
.e-card .e-card-horizontal {
  line-height: normal;
  padding: 2px;
}
.e-card.e-card-horizontal > * {
  -ms-flex: 1;
      flex: 1;
}
.e-card.e-card-horizontal .e-card-stacked {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
}
.e-card.e-card-horizontal .e-card-stacked > :first-child {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.e-card .e-card-separator {
  display: block;
}
.e-card .e-card-corner {
  border-radius: 50%;
}
.e-card .e-card-header {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: normal;
  min-height: 22.5px;
  padding: 16px;
  width: inherit;
}
.e-card .e-card-header .e-card-content {
  padding-left: 0;
  padding-right: 0;
}
.e-card .e-card-header .e-card-actions {
  -ms-flex-pack: start;
      justify-content: flex-start;
}
.e-card .e-card-header .e-card-header-image {
  -ms-flex-item-align: center;
      align-self: center;
  display: -ms-flexbox;
  display: flex;
}
.e-card .e-card-header .e-card-header-caption {
  -ms-flex-item-align: center;
      align-self: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
  padding: 0 0 0 7.5px;
}
.e-card .e-card-header .e-card-header-caption:first-child {
  padding: 0;
}
.e-card .e-card-header .e-card-header-caption .e-card-header-title,
.e-card .e-card-header .e-card-header-caption .e-card-sub-title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-card .e-card-header .e-card-header-caption .e-card-header-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
}
.e-card .e-card-header .e-card-header-caption .e-card-sub-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  padding: 10px 0 0;
}
.e-card .e-card-header .e-card-header-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 30px;
  width: 30px;
}
.e-card .e-card-header .e-card-corner {
  border-radius: 50%;
}
.e-card .e-card-image {
  background-size: cover;
  min-height: 112.5px;
  position: relative;
  width: 100%;
}
.e-card .e-card-image .e-card-title {
  bottom: 0;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 27px;
  min-height: 27px;
  overflow: hidden;
  padding: 0 12px;
  position: absolute;
  text-overflow: ellipsis;
  width: inherit;
}
.e-card .e-card-image .e-card-title.e-card-top-left, .e-card .e-card-image .e-card-title.e-card-top-right {
  bottom: auto;
  top: 0;
}
.e-card .e-card-image .e-card-title.e-card-top-right {
  text-align: right;
}
.e-card .e-card-image .e-card-title.e-card-bottom-right {
  text-align: right;
}
.e-card .e-card-actions {
  box-sizing: border-box;
  display: inline-block;
  -ms-flex-pack: start;
      justify-content: flex-start;
  padding: 16px;
}
.e-card .e-card-actions.e-card-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 16px;
}
.e-card .e-card-actions.e-card-vertical .e-card-btn,
.e-card .e-card-actions.e-card-vertical a {
  -ms-flex-item-align: initial;
      -ms-grid-row-align: initial;
      align-self: initial;
  box-sizing: border-box;
  display: inline-block;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 0 0 6px 0;
  text-align: center;
  width: 100%;
}
.e-card .e-card-actions.e-card-vertical .e-card-btn:last-child,
.e-card .e-card-actions.e-card-vertical a:last-child {
  margin-bottom: 0;
}
.e-card .e-card-actions button,
.e-card .e-card-actions a {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
}
.e-card .e-card-actions .e-card-btn span,
.e-card .e-card-actions .e-card-btn span.e-icons {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 24px;
  width: 24px;
}
.e-card .e-card-actions .e-card-btn span::before,
.e-card .e-card-actions .e-card-btn span.e-icons::before {
  -ms-flex-item-align: center;
      align-self: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  width: inherit;
}
.e-card .e-card-actions .e-card-btn > * {
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
}
.e-card .e-card-actions .e-card-btn,
.e-card .e-card-actions a {
  border-radius: 2px;
}
.e-card .e-card-actions .e-card-btn,
.e-card .e-card-actions a {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  line-height: 31px;
  margin: 0 0 0 6px;
  min-height: 31px;
  padding: 0 6px;
  text-decoration: none;
  text-transform: none;
}
.e-card .e-card-actions .e-card-btn:first-child,
.e-card .e-card-actions a:first-child {
  margin-left: 0;
}
.e-card .e-card-content {
  font-size: 14px;
  line-height: normal;
  padding: 16px;
  word-wrap: break-word;
}
.e-card .e-card-content + :not(.e-card-content),
.e-card .e-card-content + .e-card-actions.e-card-vertical {
  margin-top: 0;
  padding-top: 0;
}
.e-card .e-card-content,
.e-card .e-card-content p {
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*! card theme */
/* stylelint-disable property-no-vendor-prefix */
.e-card {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
  color: #212529;
  outline: none;
}
.e-card:hover {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.e-card:focus {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.e-card:active {
  background-color: #fff;
  border-color: #007bff;
}
.e-card .e-card-separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.e-card .e-card-header-caption .e-card-header-title {
  color: #212529;
}
.e-card .e-card-header-caption .e-card-sub-title {
  color: #6c757d;
}
.e-card .e-card-image .e-card-title {
  background-color: rgba(0, 0, 0, 0.35);
  color: #fff;
}
.e-card .e-card-actions .e-card-btn span,
.e-card .e-card-actions .e-card-btn span.e-icons {
  color: #f8f9fa;
}
.e-card .e-card-actions .e-card-btn,
.e-card .e-card-actions a {
  background-color: #007bff;
  border: 1px solid #0062cc;
  color: #fff;
  outline: 0;
}
.e-card .e-card-actions .e-card-btn:hover,
.e-card .e-card-actions a:hover {
  background-color: #0069d9;
  border: 1px solid #0062cc;
  color: #fff;
}
.e-card .e-card-actions .e-card-btn:focus,
.e-card .e-card-actions a:focus {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: #fff;
}
.e-card .e-card-actions .e-card-btn:active,
.e-card .e-card-actions a:active {
  background-color: #0062cc;
  border: 1px solid #005cbf;
  color: #fff;
}
.e-card .e-card-content {
  color: #212529;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler::before {
  content: "\e781";
  font-family: "e-icons";
  font-size: 14px;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler.e-template-resize-handler::before {
  content: "";
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler::before {
  content: "\e71b";
  font-family: "e-icons";
  font-size: 14px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler.e-template-resize-handler::before {
  content: "";
}

.e-bigger .e-splitter .e-split-bar .e-resize-handler::before {
  font-size: 16px;
}
.e-bigger.e-splitter .e-split-bar .e-resize-handler::before {
  font-size: 16px;
}

.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical {
  border-width: 1px;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
  width: 100%;
}
.e-splitter.e-splitter-horizontal .e-pane, .e-splitter.e-splitter-vertical .e-pane {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: normal;
}
.e-splitter.e-splitter-horizontal .e-pane.e-scrollable, .e-splitter.e-splitter-vertical .e-pane.e-scrollable {
  overflow: auto;
}
.e-splitter.e-splitter-horizontal .e-pane.e-static-pane, .e-splitter.e-splitter-vertical .e-pane.e-static-pane {
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal, .e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal {
  overflow: auto;
}
.e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal.e-pane-hidden, .e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal.e-pane-hidden {
  -ms-flex-preferred-size: 0 !important;
      flex-basis: 0 !important; /* stylelint-disable-line declaration-no-important */
  overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
}
.e-splitter.e-splitter-horizontal {
  -ms-flex-direction: row;
      flex-direction: row;
}
.e-splitter.e-splitter-horizontal.e-pane.e-scrollable {
  overflow: hidden;
}
.e-splitter.e-splitter-horizontal.e-rtl {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}
.e-splitter.e-splitter-horizontal.e-rtl .e-split-bar.e-split-bar-horizontal {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}
.e-splitter.e-splitter-horizontal.e-rtl .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::before,
.e-splitter.e-splitter-horizontal.e-rtl .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::after {
  right: auto;
}
.e-splitter.e-splitter-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
}
.e-splitter.e-splitter-vertical.e-pane.e-scrollable {
  overflow: hidden;
}
.e-splitter.e-splitter-vertical .e-pane-vertical {
  overflow: auto;
}
.e-splitter.e-splitter-vertical .e-pane-vertical.e-pane-hidden {
  -ms-flex-preferred-size: 0 !important;
      flex-basis: 0 !important; /* stylelint-disable-line declaration-no-important */
  overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
}
.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
  right: auto;
}
.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down) {
  border-radius: 50%;
  bottom: 20px;
  right: 22px;
}
.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down {
  border-radius: 50%;
  left: 22px;
  right: auto;
  top: 20px;
}
.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
  right: auto;
}
.e-splitter .e-split-bar.e-split-bar-horizontal {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  -ms-flex-pack: center;
      justify-content: center;
  min-width: 1px;
  z-index: 15;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-icon-hidden {
  visibility: hidden;
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-resizable-split-bar {
  cursor: col-resize;
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-resizable-split-bar::after {
  content: "";
  cursor: col-resize;
  display: block;
  height: 100%;
  position: absolute;
  width: 16px;
  z-index: 10;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 18px;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
  width: 1px;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler.e-hide-handler {
  visibility: hidden;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::before,
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::after {
  left: 8px;
  right: 3px;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow {
  border-image: none;
  border-width: 1px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 26px;
  padding: 1px 12px;
  position: relative;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::after {
  border-radius: 16px;
  content: "";
  height: 8px;
  position: absolute;
  transform: rotate(0deg);
  width: 2px;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::before {
  top: 5px;
  transform-origin: 1px 7px 0;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::after {
  top: 11px;
  transform-origin: 1px 1px 0;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right) {
  border-radius: 50%;
  right: 5px;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right {
  border-radius: 50%;
  left: 5px;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right::before, .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right::after {
  right: 8px;
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-last-bar:not(.e-resizable-split-bar) {
  margin: 0 3px;
}
.e-splitter .e-split-bar.e-split-bar-horizontal:not(.e-resizable-split-bar) {
  margin: 0 0 0 3px;
}
.e-splitter .e-split-bar.e-split-bar-vertical {
  -ms-flex-align: center;
      align-items: center;
  border-left: none;
  border-right: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  -ms-flex-pack: center;
      justify-content: center;
  min-height: 1px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-icon-hidden {
  visibility: hidden;
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-resizable-split-bar {
  cursor: row-resize;
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-resizable-split-bar::after {
  content: "";
  cursor: row-resize;
  display: block;
  height: 16px;
  position: absolute;
  width: 100%;
  z-index: 12;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 1px;
  -ms-flex-pack: center;
      justify-content: center;
  width: 18px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler.e-hide-handler {
  visibility: hidden;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
  left: 10px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow {
  border-image: none;
  border-width: 0;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 26px;
  padding: 10px 3px 10px 18px;
  position: relative;
  width: 26px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::after {
  border-radius: 16px;
  content: "";
  height: 8px;
  position: absolute;
  transform: rotate(90deg);
  width: 2px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before {
  top: 3px;
  transform-origin: 2px 7px 0;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
  left: 11px;
  top: 8px;
  transform-origin: 1px 1px 0;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::before {
  left: 12px;
  top: 8px;
  transform-origin: 1px 6px 0;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
  left: 10px;
  top: 12px;
  transform-origin: 1px 2px 0;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down) {
  border-radius: 50%;
  bottom: 20px;
  left: 22px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down {
  border-radius: 50%;
  right: 22px;
  top: 20px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::before, .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
  right: 10px;
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-last-bar:not(.e-resizable-split-bar) {
  margin: 3px 0;
}
.e-splitter .e-split-bar.e-split-bar-vertical:not(.e-resizable-split-bar) {
  margin: 3px 0 0;
}

.e-bigger.e-splitter .e-pane {
  font-size: 14px;
}
.e-bigger.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
  height: 20px;
}
.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
  width: 20px;
}
.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
  margin-top: -2px;
}
.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
  margin-top: 2px;
}

.e-rtl .e-splitter.e-splitter-horizontal {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}

.e-bigger .e-splitter .e-pane {
  font-size: 14px;
}
.e-bigger .e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
  height: 20px;
}
.e-bigger .e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
  width: 20px;
}

.e-content-placeholder.e-splitter.e-placeholder-splitter {
  background-size: 100px 110px;
  min-height: 110px;
}

.e-bigger .e-content-placeholder.e-splitter.e-placeholder-splitter,
.e-bigger.e-content-placeholder.e-splitter.e-placeholder-splitter {
  background-size: 100px 110px;
  min-height: 110px;
}

.e-splitter.e-ie .e-navigate-arrow.e-arrow-left {
  margin-left: -26px;
}

.e-splitter.e-ie .e-split-bar-horizontal .e-resize-handler {
  -ms-flex-direction: column;
      flex-direction: column;
}

.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical {
  border: solid 1px #dee2e6;
}
.e-splitter.e-splitter-horizontal .e-pane, .e-splitter.e-splitter-vertical .e-pane {
  color: #212529;
}
.e-splitter.e-splitter-horizontal .e-pane.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-horizontal .e-pane.e-splitter.e-splitter-vertical, .e-splitter.e-splitter-vertical .e-pane.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical .e-pane.e-splitter.e-splitter-vertical {
  border: transparent;
}
.e-splitter .e-split-bar.e-split-bar-horizontal {
  background: #dee2e6;
  border-left: none;
  border-right: none;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
  background: #fff;
  color: #495057;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-icon-hidden) {
  background-color: transparent;
  border-color: transparent;
  color: transparent;
  transition: background-color 500ms ease-out;
}
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-icon-hidden)::before, .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-icon-hidden)::after {
  background-color: #007bff;
  opacity: 0;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active {
  background: #007bff;
  border-left: none;
  border-right: none;
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-resize-handler, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-resize-handler {
  background: #fff;
  color: #007bff;
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-left, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-left, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right {
  background-color: #fff;
  border-color: rgba(0, 123, 255, 0.5);
  border-width: 1px;
  opacity: 0.9;
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-hover .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-active .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-hover .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-active .e-navigate-arrow::before {
  background-color: #007bff;
  opacity: 1;
  transform: rotate(40deg);
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-hover .e-navigate-arrow::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-active .e-navigate-arrow::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-hover .e-navigate-arrow::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-active .e-navigate-arrow::after {
  background-color: #007bff;
  opacity: 1;
  transform: rotate(-40deg);
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right::before {
  background-color: #007bff;
  opacity: 1;
  transform: rotate(-40deg);
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right::after {
  background-color: #007bff;
  opacity: 1;
  transform: rotate(40deg);
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active {
  background: #007bff;
}
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-resize-handler {
  background: #fff;
  color: #007bff;
}
.e-splitter .e-split-bar.e-split-bar-vertical {
  background: #dee2e6;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
  background: #fff;
  color: #495057;
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active {
  background: #007bff;
  border-left: none;
  border-right: none;
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down {
  background-color: #fff;
  border-color: rgba(0, 123, 255, 0.5);
  border-width: 1px;
  opacity: 0.9;
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-resize-handler, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-resize-handler {
  background: #fff;
  color: #007bff;
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up::before, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up::before {
  background-color: #007bff;
  opacity: 1;
  transform: rotate(125deg);
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up::after, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up::after {
  background-color: #007bff;
  opacity: 1;
  transform: rotate(50deg);
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down::before, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down::before {
  background-color: #007bff;
  opacity: 1;
  transform: rotate(55deg);
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down::after, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down::after {
  background-color: #007bff;
  opacity: 1;
  transform: rotate(125deg);
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow {
  background-color: transparent;
  border-color: transparent;
  color: transparent;
  transition: background-color 300ms ease-out;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::after {
  background-color: #007bff;
  opacity: 0;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active {
  background: #007bff;
}
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-resize-handler {
  background: #fff;
  color: #007bff;
}
.e-splitter.e-disabled {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable-line no-empty-source */ /*! component's theme wise override bootstrap-definitions and variables */
/* stylelint-disable */
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-east {
  height: 100%;
  padding: 20px 0;
  right: 1px;
  top: 0;
  width: 12px;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-west {
  height: 100%;
  left: 0;
  padding: 20px 0;
  top: 0;
  width: 12px;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north {
  height: 12px;
  padding: 0 20px;
  top: 1px;
  width: 100%;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south {
  bottom: 1px;
  height: 12px;
  padding: 0 20px;
  width: 100%;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-east {
  bottom: 0;
  right: 1px;
  z-index: 10;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north-west {
  left: 2px;
  top: 2px;
  z-index: 10;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north-east {
  right: 2px;
  top: 2px;
  z-index: 10;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-west {
  bottom: 1px;
  left: 1px;
  z-index: 10;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-east::before, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-east::before {
  bottom: 4px;
  content: "\e7cf";
  font-size: 12px;
  position: absolute;
  right: 4px;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-west::before, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-west::before {
  bottom: 4px;
  content: "\e7cf";
  font-size: 12px;
  left: 4px;
  position: absolute;
  transform: rotateY(180deg);
}

.e-dashboardlayout.e-control .e-dashboard-gridline-table {
  background: #f8f9fa;
  border-collapse: collapse;
  height: 100%;
  width: 100%;
}
.e-dashboardlayout.e-control .e-dashboard-gridline-table tbody tr td.e-dashboard-gridline {
  border: 1px dotted #ced4da;
  position: absolute;
}
.e-dashboardlayout.e-control.e-responsive {
  width: 100% !important;
}
.e-dashboardlayout.e-control.e-prevent {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.e-dashboardlayout.e-control .e-panel {
  border-radius: 4px;
}
.e-dashboardlayout.e-control .e-panel:hover {
  border: 1px #ced4da solid;
}
.e-dashboardlayout.e-control .e-panel:hover .e-panel-container .e-resize.e-dl-icon {
  display: block;
}
.e-dashboardlayout.e-control .e-panel:active {
  border: 1px #007bff solid;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-dl-icon {
  display: none;
}
.e-dashboardlayout.e-control .e-panel.e-panel-transition {
  transition: top 0.5s, left 0.5s;
}
.e-dashboardlayout.e-control .e-panel .e-panel-header {
  border-bottom: 1px solid #e9ecef;
  color: #212529;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  font-weight: 200;
  height: 28px;
  padding: 5px 10px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-header div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-dashboardlayout.e-control .e-panel .e-panel-header .e-header-content {
  display: inline-block;
}
.e-dashboardlayout.e-control .e-panel .e-panel-header .e-header-template {
  float: right;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container {
  height: 100%;
  width: 100%;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-content .e-blazor-template {
  height: inherit;
  width: inherit;
}
.e-dashboardlayout.e-control .e-panel {
  border: 1px #dee2e6 solid;
  height: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single, .e-dashboardlayout.e-control .e-panel .e-resize.e-double {
  position: absolute;
  -ms-touch-action: none;
      touch-action: none;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north, .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south {
  border: 1px #007bff solid;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-east:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-east:hover {
  cursor: e-resize;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-west:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-west:hover {
  cursor: w-resize;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north:hover {
  cursor: n-resize;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south:hover {
  cursor: s-resize;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north-west:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north-west:hover {
  cursor: nw-resize;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north-east:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north-east:hover {
  cursor: ne-resize;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-west:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-west:hover {
  cursor: sw-resize;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-east:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-east:hover {
  cursor: se-resize;
}
.e-dashboardlayout.e-control .e-panel .e-resize.e-single::before, .e-dashboardlayout.e-control .e-panel .e-resize.e-double::before {
  font-family: "e-icons";
  position: absolute;
}
.e-dashboardlayout.e-control .e-drag:hover {
  cursor: move;
}
.e-dashboardlayout.e-control .e-drag-restrict.e-drag:hover,
.e-dashboardlayout.e-control .e-drag-restrict .e-drag:hover {
  cursor: default;
}
.e-dashboardlayout.e-control .e-panel.e-dragging, .e-dashboardlayout.e-control .e-panel.e-item-moving {
  cursor: move;
  z-index: 1111 !important;
}
.e-dashboardlayout.e-control .e-panel.e-rtl .e-panel-header .e-header-template {
  float: left;
}
.e-dashboardlayout.e-control .e-holder {
  background: rgba(0, 123, 255, 0.2);
  border: 2px #007bff dotted;
  border-radius: 4px;
  position: absolute;
  border-radius: 4px;
}
.e-dashboardlayout.e-control .e-holder.e-holder-transition {
  transition: top 0.3s, left 0.3s;
}
.e-dashboardlayout.e-control .e-panel.e-bigger .e-panel-header {
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  padding: 0 12px;
}

.e-bigger .e-dashboardlayout.e-control .e-panel .e-panel-header {
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  padding: 0 12px;
}
.e-content-placeholder.e-dashboardlayout.e-placeholder-dashboardlayout {
  height: 100%;
  width: 100%;
}

.e-dashboardlayout.e-control {
  display: block;
  position: relative;
}
.e-dashboardlayout.e-control .e-panel {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: absolute;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-header {
  color: #212529;
}
.e-dashboardlayout.e-control .e-panel {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: absolute;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-header {
  color: #212529;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-single {
  background: none;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double {
  color: #495057;
  font-size: 8px;
  height: 16px;
  width: 16px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-east-double {
  bottom: -5px;
  right: -6px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-west-double {
  bottom: -5px;
  left: -6px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-west-double {
  left: -6px;
  top: -5px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-east-double {
  right: -6px;
  top: -5px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-east-shrink {
  right: -6px;
  top: -5px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-west-shrink {
  left: -6px;
  top: -5px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-west-shrink {
  bottom: -5px;
  left: -6px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-east-shrink {
  bottom: -5px;
  right: -6px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-east-expand {
  right: -6px;
  top: -5px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-west-expand {
  left: -6px;
  top: -5px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-west-expand {
  bottom: -5px;
  left: -6px;
}
.e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-east-expand {
  bottom: -5px;
  right: -6px;
}
.e-dashboardlayout.e-control .e-panel.e-bigger .e-panel-header {
  color: #495057;
}